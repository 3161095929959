/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "phonak-audéo-marvel-transforming-hearing-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-aud%C3%A9o-marvel-transforming-hearing-technology",
    "aria-label": "phonak audéo marvel transforming hearing technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Audéo™ Marvel: Transforming Hearing Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As one of the leading ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " manufacturers, ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/",
    className: "c-md-a"
  }, "Phonak"), " is once again changing the face of the industry. In its new Marvel receiver-in-canal (RIC) platform, Phonak leverages its proprietary AutoSense™ 3.0 operating system to deliver an unparalleled natural hearing experience. Combined with StereoZoom technology and artificial intelligence, you can hear everything around you rather than traditional one-directional inputs found in other devices. Plus, enjoy the convenience of rechargeable batteries and Bluetooth capabilities to listen to what you want, when you want."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-meets-artificial-intelligence-autosense-os-30",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-meets-artificial-intelligence-autosense-os-30",
    "aria-label": "hearing meets artificial intelligence autosense os 30 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Meets Artificial Intelligence: AutoSense™ OS 3.0"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now that artificial intelligence (AI) is becoming a mainstay in modern technology, ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid manufacturers"), " are beginning to leverage the power of AI to dramatically improve the hearing experience. Through a new industry-first hearing aid technology, Phonak has harnessed the power of AI in its new AutoSense™ OS 3.0 operating system."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "AutoSense™ OS 3.0 is an adaptive operating system that automatically recognizes changes in the wearer’s acoustic environment and constantly adapts the compression speed to fit the listening situation. Studies have shown that AutoSense™ OS 3.0 provides significant reductions in listening effort for those with moderate hearing loss in speech-in-noise situations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak’s new line of Audéo™ Marvel hearing aids featuring AutoSense™ OS 3.0 also includes Binaural VoiceStream Technology™, which streams the full audio bandwidth bi-directionally between both hearing aids in real-time, delivering better speech understanding in noise with minimal listening effort."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-voicestream-technology-for-natural-sound-quality",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-voicestream-technology-for-natural-sound-quality",
    "aria-label": "binaural voicestream technology for natural sound quality permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural VoiceStream Technology for Natural Sound Quality"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By integrating ", React.createElement(_components.a, {
    href: "/hearing-aids/types/binaural",
    className: "c-md-a"
  }, "binaural technology (meaning “having two ears”)"), ", Phonak’s new Marvel hearing aids mimic the way the brain processes and understands sound. Combined with breakthrough AutoSense™ OS 3.0 technology, both left and right hearing aids work in tandem to communicate with each other. They adapt to changes in the sound environment in real time to deliver more natural hearing, speech understanding, and sound quality."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A 2018 study found that Phonak’s binaural adaptive beamformer technology (StereoZoom), which the Marvel series includes, led to a 15% increase in overall communication and a reduction listening effort by 19%, meaning participants needed to lean in less often towards the person speaking. Results of the study showed that participants using hearing aids with Phonak’s StereoZoom technology were, therefore, more likely to be more engaged in social interactions than those whose hearing aids did not use this technology."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The binaural VoiceStream technology also offers advantages over other competing technologies when speaking with someone over the phone or in other situations where you can only hear—and not see—your conversation partner."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "clearer-speech-understanding-in-challenging-environments",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#clearer-speech-understanding-in-challenging-environments",
    "aria-label": "clearer speech understanding in challenging environments permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Clearer Speech Understanding in Challenging Environments"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For individuals with moderate to severe hearing loss, understanding speech in noisy environments can be a difficult task, even when wearing appropriately-fitting hearing aids. Recent studies have shown that customers are more satisfied with hearing aids that successfully minimize background noise and allow the customer to more clearly hear and understand speech in these environments. Related studies have also shown that the effort needed to understand speech in noisy and challenging environments was reduced by 18% when study participants were fitted with hearing aids featuring Phonak’s StereoZoom technology."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeable-lithium-ion-batteries-for-on-the-go-convenience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-lithium-ion-batteries-for-on-the-go-convenience",
    "aria-label": "rechargeable lithium ion batteries for on the go convenience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable Lithium-Ion Batteries for On-the-Go Convenience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak’s Marvel hearing aids come with ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "rechargeable lithium-ion batteries"), " that are engineered to last for 6 years. They provide a full day of hearing, even ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "when streaming from connected Bluetooth devices"), ". The Mini Charger case also means you can charge on-the-go and at your convenience. And there’s no need to worry about turning the Marvel hearing aids on or off – they automatically switch on when removed from the charging case and turn off when placed back in the case."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connect-to-smartphones-tvs-and-other-bluetooth-enabled-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connect-to-smartphones-tvs-and-other-bluetooth-enabled-devices",
    "aria-label": "connect to smartphones tvs and other bluetooth enabled devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connect to Smartphones, TVs, and other Bluetooth-enabled Devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever been frustrated by the need to remove one or both hearing devices just to put in earphones to listen to music or talk on the phone? Phonak’s new Marvel platform has solved this problem by including Bluetooth capabilities in its devices. This means you simply connect your Marvel hearing aid with your Bluetooth-enabled smartphone, TV, or other device and stream the sound directly through the hearing aid. Now, you can listen to what you want, how you want."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Phonak Marvel now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
